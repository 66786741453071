import {
	AXIS_BANK,
	BANK_OF_BARODA_BANK,
	CANARA_BANK,
	HDFC_BANK,
	ICICI_BANK,
	IDBI_BANK,
	INDUSIND_BANK,
	ISSUER_FIRSTNAME_BAJAJ,
	ISSUER_FIRSTNAME_MAHINDRA,
	ISSUER_FIRSTNAME_SHRIRAM,
	ISSUER_FIRSTNAME_UNITY,
	KOTAK_BANK,
	PNB_BANK,
	SBI_BANK,
} from './constants'

export const HOME_PAGE = 'home'
export const ABOUT_PAGE = 'about'
export const SUPPORT_PAGE = 'support'
export const CALCULATOR_PAGE = 'calculator'
export const BLOG_PAGE = 'blog'

const IMAGE_BASE_URL = 'https://bhihtsmosmghzjnmhufc.supabase.co/storage/v1/object/public/superfd-images'

export function getFdImageFromName(fdName: string) {
	switch (fdName) {
		case ISSUER_FIRSTNAME_BAJAJ:
			return `${IMAGE_BASE_URL}/website/bajaj.png`
		case ISSUER_FIRSTNAME_SHRIRAM:
			return `${IMAGE_BASE_URL}/website/shriram.png`
		case ISSUER_FIRSTNAME_MAHINDRA:
			return `${IMAGE_BASE_URL}/website/mahindra.png`
		case ISSUER_FIRSTNAME_UNITY:
			return `${IMAGE_BASE_URL}/website/unity.png`
		case SUPPORT_PAGE:
			return `${IMAGE_BASE_URL}/website/support.png`
		case SBI_BANK:
			return `${IMAGE_BASE_URL}/og-image/sbi.png`
		case HDFC_BANK:
			return `${IMAGE_BASE_URL}/og-image/hdfc.png`
		case ICICI_BANK:
			return `${IMAGE_BASE_URL}/og-image/icici.png`
		case AXIS_BANK:
			return `${IMAGE_BASE_URL}/og-image/axis.png`
		case KOTAK_BANK:
			return `${IMAGE_BASE_URL}/og-image/kotak.png`
		case INDUSIND_BANK:
			return `${IMAGE_BASE_URL}/og-image/indusind.png`
		case BANK_OF_BARODA_BANK:
			return `${IMAGE_BASE_URL}/og-image/bank_of_baroda.png`
		case CANARA_BANK:
			return `${IMAGE_BASE_URL}/og-image/canara.png`
		case PNB_BANK:
			return `${IMAGE_BASE_URL}/og-image/pnb.png`
		case IDBI_BANK:
			return `${IMAGE_BASE_URL}/og-image/idbi.png`
		case CALCULATOR_PAGE:
			return `${IMAGE_BASE_URL}/og-image/calculator.png`
		case BLOG_PAGE:
			return `${IMAGE_BASE_URL}/og-image/blog.png`
		default:
			return `${IMAGE_BASE_URL}/website/superfd-home.png`
	}
}

export function getSocialMetas({
	url,
	title = 'SuperFD',
	description = 'Earn 9.1% p.a. interest rate on your next FD',
	image = getFdImageFromName('home'),
	keywords = '',
}: {
	image?: string
	url: string
	title?: string
	description?: string
	keywords?: string
}) {
	return [
		{ title },
		{ name: 'description', content: description },
		{ name: 'keywords', content: keywords },
		{ name: 'image', content: image },
		{ name: 'og:url', content: url },
		{ name: 'og:title', content: title },
		{ name: 'og:description', content: description },
		{ name: 'og:image', content: image },
		{
			name: 'twitter:card',
			content: image ? 'summary_large_image' : 'summary',
		},
		{ name: 'twitter:creator', content: '@superfd' },
		{ name: 'twitter:site', content: '@superfd' },
		{ name: 'twitter:title', content: title },
		{ name: 'twitter:description', content: description },
		{ name: 'twitter:image', content: image },
		{ name: 'twitter:image:alt', content: title },
	]
}

export function getFdTitleFromName(fdName: string, fdId: string | undefined) {
	switch (fdName) {
		case ISSUER_FIRSTNAME_BAJAJ:
			return {
				title: 'Bajaj Finance Limited | Super FD',
				description:
					'Bajaj Finance FD Interest Rates offers upto 8.25% p.a. for regular & upto 8.5% for Senior Citizens. Check Bajaj Finance Fixed deposit (FD) Rates 2024 & Invest.',
				url: `https://www.superfd.in/fd/${fdId}`,
				image: getFdImageFromName(fdName),
				keywords:
					'Bajaj fd, Bajaj online fd, Bajaj fd rates, Bajaj fixed deposit, Bajaj Highest Interest rate, Bajaj online, Bajaj finance, Bajaj fd booking, Bajaj calculator',
			}
		case ISSUER_FIRSTNAME_SHRIRAM:
			return {
				title: 'Shriram Finance | Super FD',
				description:
					'Shriram Finance Fixed Deposit (FD) offers the highest interest rate, up to 9.07* p.a., inclusive of 0.50%* p.a. for senior citizens & 0.10%* p.a. for Women depositors.',
				url: `https://www.superfd.in/fd/${fdId}`,
				image: getFdImageFromName(fdName),
				keywords:
					'Shriram fd, Shriram online fd, Shriram fd rates, Shriram fixed deposit, Shriram Highest Interest rate, Shriram online, Shriram finance, Shriram fd booking, Shriram calculator',
			}
		case ISSUER_FIRSTNAME_MAHINDRA:
			return {
				title: 'Mahindra Finance | Super FD',
				description:
					'Mahindra and Mahindra Finance FD Interest Rates offers upto 8.1% p.a. for regular & upto 8.35% for Senior Citizens. Check Mahindra and Mahindra Finance Fixed deposit (FD) Rates 2024 & Invest.',
				url: `https://www.superfd.in/fd/${fdId}`,
				image: getFdImageFromName(fdName),
				keywords:
					'Mahindra fd, Mahindra online fd, Mahindra fd rates, Mahindra fixed deposit, Mahindra Highest Interest rate, Mahindra online, Mahindra finance, Mahindra fd booking, Mahindra calculator',
			}
		case ISSUER_FIRSTNAME_UNITY:
			return {
				title: 'Unity SF Bank | Super FD',
				description:
					'Open Fixed Deposit Account Online (FD) with Unity Bank & get up to 9% FD interest rate for general customers & up to 9.1% for Senior Citizens.',
				url: `https://www.superfd.in/fd/${fdId}`,
				image: getFdImageFromName(fdName),
				keywords:
					'Unity fd, Unity online fd, Unity fd rates, Unity fixed deposit, Unity Highest Interest rate, Unity online, Unity finance, Unity fd booking, Unity calculator',
			}
		case SBI_BANK:
			return {
				title: 'SBI Bank FD Calculator | Super FD',
				description:
					`Explore SBI's fixed deposit rates and see how they compare to higher returns offered by small finance banks. Make informed decisions for your savings.`,
				url: `https://www.superfd.in/fixed-deposit/sbi-bank-fd-rates`,
				image: getFdImageFromName(fdName),
				keywords:
					'sbi fd, sbi online fd, sbi fd rates, sbi fixed deposit, sbi Highest Interest rate, sbi online, sbi rates, sbi fd booking, sbi fd calculator, sbi calculator',
			}
		case HDFC_BANK:
			return {
				title: 'HDFC Bank FD Calculator | Super FD',
				description:
					`Discover HDFC's fixed deposit rates and how they stack up against the offerings from small finance banks. Make smarter savings choices today.`,
				url: `https://www.superfd.in/fixed-deposit/hdfc-bank-fd-rates`,
				image: getFdImageFromName(fdName),
				keywords:
					'hdfc fd, hdfc online fd, hdfc fd rates, hdfc fixed deposit, hdfc Highest Interest rate, hdfc online, hdfc rates, hdfc fd booking, hdfc fd calculator, hdfc calculator',
			}
		case ICICI_BANK:
			return {
				title: 'ICICI Bank FD Calculator | Super FD',
				description:
					`Evaluate ICICI's fixed deposit rates and see how they compare to the higher returns offered by small finance banks. Make informed investment choices.`,
				url: `https://www.superfd.in/fixed-deposit/icici-bank-fd-rates`,
				image: getFdImageFromName(fdName),
				keywords:
					'icici fd, icici online fd, icici fd rates, icici fixed deposit, icici Highest Interest rate, icici online, icici rates, icici fd booking, icici fd calculator, icici calculator',
			}
		case AXIS_BANK:
			return {
				title: 'AXIS Bank FD Calculator | Super FD',
				description:
					`Explore Axis Bank's fixed deposit rates and see how they compare to the higher returns offered by small finance banks. Make informed choices for your investments.`,
				url: `https://www.superfd.in/fixed-deposit/axis-bank-fd-rates`,
				image: getFdImageFromName(fdName),
				keywords:
					'axis fd, axis online fd, axis fd rates, axis fixed deposit, axis Highest Interest rate, axis online, axis rates, axis fd booking, axis fd calculator, axis calculator',
			}
		case KOTAK_BANK:
			return {
				title: 'Kotak Bank FD Calculator | Super FD',
				description:
					`Analyze Kotak Mahindra Bank's fixed deposit interest rates and discover how they compare to the more attractive returns from small finance banks. Make smart investment choices.`,
				url: `https://www.superfd.in/fixed-deposit/kotak-bank-fd-rates`,
				image: getFdImageFromName(fdName),
				keywords:
					'kotak fd, kotak mahindra fd, kotak mahindra fd, kotak mahindra bank, kotak online fd, kotak fd rates, kotak fixed deposit, kotak Highest Interest rate, kotak online, kotak rates, kotak fd booking, kotak fd calculator, kotak calculator',
			}
		case INDUSIND_BANK:
			return {
				title: 'IndusInd Bank FD Calculator | Super FD',
				description:
					`Explore IndusInd Bank’s fixed deposit rates and see how they offer competitive returns across various tenures. Make informed saving decisions.`,
				url: `https://www.superfd.in/fixed-deposit/indusind-bank-fd-rates`,
				image: getFdImageFromName(fdName),
				keywords:
					'indusind fd, indusind online fd, indusind fd rates, indusind fixed deposit, indusind Highest Interest rate, indusind online, indusind rates, indusind fd booking, indusind fd calculator, indusind calculator',
			}
		case BANK_OF_BARODA_BANK:
			return {
				title: 'Bank of Baroda FD Calculator | Super FD',
				description:
					`Explore Bank of Baroda's fixed deposit rates, offering stable returns across various tenures. Achieve your financial goals with confidence.`,
				url: `https://www.superfd.in/fixed-deposit/bank_of_baroda-bank-fd-rates`,
				image: getFdImageFromName(fdName),
				keywords:
					'bob fd, bob fd rates, bob fixed deposit, bob fd online, bank of baroda fd, bank of baroda online fd, bank of baroda fd rates, bank of baroda fixed deposit, bank of baroda Highest Interest rate, bank of baroda online, bank of baroda rates, bank of baroda fd booking, bank of baroda fd calculator, bank of baroda calculator',
			}
		case CANARA_BANK:
			return {
				title: 'Canara Bank FD Calculator | Super FD',
				description:
					`Review Canara Bank's fixed deposit rates and see how they compare to the more attractive returns offered by small finance banks. Make informed savings decisions.`,
				url: `https://www.superfd.in/fixed-deposit/canara-bank-fd-rates`,
				image: getFdImageFromName(fdName),
				keywords:
					'canara fd, canara online fd, canara fd rates, canara fixed deposit, canara Highest Interest rate, canara online, canara rates, canara fd booking, canara fd calculator, canara calculator',
			}
		case PNB_BANK:
			return {
				title: 'PNB Bank FD Calculator | Super FD',
				description:
					`Discover PNB's fixed deposit rates offering competitive returns for a secure future.`,
				url: `https://www.superfd.in/fixed-deposit/pnb-bank-fd-rates`,
				image: getFdImageFromName(fdName),
				keywords:
					'pnb fd, pnb online fd, pnb fd rates, pnb fixed deposit, punjab national bank, punjab national bank fd, punjab national bank online fd, punjab national bank fd rates, pnb Highest Interest rate, pnb online, pnb rates, pnb fd booking, pnb fd calculator, pnb calculator',
			}
		case IDBI_BANK:
			return {
				title: 'IDBI Bank FD Calculator | Super FD',
				description:
					`Explore IDBI Bank's fixed deposit rates and see how they compare to the higher returns offered by small finance banks. Make informed choices for your investments.`,
				url: `https://www.superfd.in/fixed-deposit/idbi-bank-fd-rates`,
				image: getFdImageFromName(fdName),
				keywords:
					'idbi fd, idbi online fd, idbi fd rates, idbi fixed deposit, idbi Highest Interest rate, idbi online, idbi rates, idbi fd booking, idbi fd calculator, idbi calculator',
			}
		default:
			return {
				title: 'Super FD',
				description: 'Earn 9.1% p.a. interest rate on your next FD',
				url: `https://www.superfd.in`,
				image: getFdImageFromName('home'),
				keywords:
					'SuperFD fd, SuperFD online fd, SuperFD fd rates, SuperFD fixed deposit, SuperFD Highest Interest rate, SuperFD online, SuperFD finance, SuperFD fd booking, SuperFD calculator',
			}
	}
}

export function getMetaInfoForPages(pageName: string) {
	switch (pageName) {
		case SUPPORT_PAGE:
			return {
				title: 'Support | Super FD',
				description:
					'You can reach out to us on Email - support@superfd.in or WhatsApp - +91 9972749625',
				url: `https://www.superfd.in/support`,
				image: getFdImageFromName(pageName),
				keywords:
					'superfd contact, superfd customer support, superfd call, superfd email, superfd whatsapp , superfd customer care, fd contact, fd online support',
			}
		case ABOUT_PAGE:
			return {
				title: 'About Us | Super FD',
				description:
					'Super FD is an Investment Platform focusing only on Fixed Return products, Our team of Investment Experts curate the Best Fixed Return products based on several factors like Issuer’s Credibility, Interest rate and Yield.',
				url: `https://www.superfd.in/about`,
				image: getFdImageFromName('home'),
				keywords:
					'superfd about us, about superfd, superfd info, superfd company info, superfd company details, superfd more info',
			}
		case HOME_PAGE:
			return {
				title: 'Home | Super FD',
				description:
					'Earn 9.1% p.a. interest rate on your next FD.Super FD is an Investment Platform focusing only on Fixed Return products, Our team of Investment Experts curate the Best Fixed Return products based on several factors like Issuer’s Credibility, Interest rate and Yield.',
				url: `https://www.superfd.in`,
				image: getFdImageFromName('home'),
				keywords:
					'Book FD, Fixed Deposit, Highest Interest rate FD, online fd, sbi fd, sbi fixed deposit, hdfc fd, hdfc fixed deposit, book fd online',
			}
		case CALCULATOR_PAGE:
			return {
				title: 'FD Calculator | Super FD',
				description:
					'Check our FD calculator and find out your returns.',
				url: `https://www.superfd.in/calculator`,
				image: getFdImageFromName(CALCULATOR_PAGE),
				keywords:
					'fd calculator, Fixed Deposit calculator, higher return fd calculator, online fd calculator, fixed deposti calculator, super fd calculator',
			}
			case BLOG_PAGE:
				return {
					title: 'Blogs | Super FD',
					description:
						'Explaining the effects that complicated financial mechanisms have on the simplest of investments.',
					url: `https://www.superfd.in/blog`,
					image: getFdImageFromName(CALCULATOR_PAGE),
					keywords:
						'fd blog, Fixed Deposit blog, higher return fd blogs, online fd calculator, fixed deposit blogs, super fd blogs',
			}
		default:
			return {
				title: 'Super FD',
				description: 'Earn 9.1% p.a. interest rate on your next FD',
				url: `https://www.superfd.in`,
				image: getFdImageFromName('home'),
				keywords:
					'SuperFD fd, SuperFD online fd, SuperFD fd rates, SuperFD fixed deposit, SuperFD Highest Interest rate, SuperFD online, SuperFD finance, SuperFD fd booking, SuperFD calculator',
			}
	}
}
